import React from 'react';
import NavbarInternal from '../../layout/NavbarInternal';
import Section from './section';

const login = () => {
    return (
        <React.Fragment>
            <NavbarInternal />
            <Section />
        </React.Fragment>
    )
}

export default login;