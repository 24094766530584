import { Skeleton } from "primereact/skeleton";
import React from "react";

const LoadingSkeleton = () => {
    return (
        <div className="border-round border-1 surface-border p-4 surface-card">
            <div className="flex mb-3">
                <Skeleton shape="circle" size="4rem" className="mr-2"></Skeleton>
                <div>
                    <Skeleton width="10rem" className="mb-2"></Skeleton>
                    <Skeleton width="5rem" className="mb-2"></Skeleton>
                    <Skeleton height=".5rem"></Skeleton>
                </div>
            </div>
            <Skeleton width="100%" height="150px"></Skeleton>
        </div>
    );
};

export default LoadingSkeleton;