import { useFormik } from "formik";
import dayjs from "dayjs";
import * as yup from "yup";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Grid from "@mui/material/Unstable_Grid2";
import LoadingButton from "@mui/lab/LoadingButton";
import InputAdornment from "@mui/material/InputAdornment";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import React, { useState } from "react";
import { Autocomplete, TextField, FormControl } from "@mui/material";

const axios = require("axios");

const minimumAge = 16;

const currentDate = new Date();
const youngestDob = new Date(
  currentDate.setFullYear(currentDate.getFullYear() - minimumAge)
);

const validationSchema = yup.object({
  firstName: yup
    .string()
    .min(2)
    .max(200)
    .required("First name is required")
    .matches(/^[A-Za-z\s]+$/, "Please enter a valid name (letters and spaces only)"),
  lastName: yup
    .string()
    .min(2)
    .max(200)
    .required("Last name is required")
    .matches(/^[A-Za-z\s]+$/, "Please enter a valid last name (letters and spaces only)"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Please enter a valid email"
    ),
  dob: yup
    .date()
    .required("Date of birth is required")
    .max(youngestDob, `No under ${minimumAge}`),
  password: yup
    .string()
    .required("Please enter a password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
      "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, and one number"
    ),
  verifyPassword: yup
    .string()
    .required("Please re-enter your password")
    .oneOf([yup.ref('password'), null], 'Passwords do not match.'),
  mobile: yup
    .string()
    .required("Please enter a mobile number")
    .matches(
      /^\+\d{1,15}$/,
      "Please enter a valid international mobile number"
    ),
  occupation: yup
    .string()
    .required("Please enter your occupation")
    .matches(/^[A-Za-z0-9\s]+$/, "Please enter a valid value (letters, numbers, and spaces only)"),
  postCode: yup.string().required("Please enter your post code"),
  address1: yup.string().required("Please enter your full address"),
});


const QuickRegisterForm = ({ submit, setLoginFormData }) => {
  const [dobTouched, setDobTouched] = React.useState(false);

  const [addressData, setAddressData] = useState({
    addressOptions: [],
    addressVal: null,
  });

  // TODO cleann up this shit
  const [showAddressDropdown, setShowAddressses] = useState(true);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      dob: "DD-MM-YYYY", // new Date(1990, 0, 1),
      password: "",
      verifyPassword: "",
      mobile: "+",
      occupation: "",
      postCode: "",
      fullAddress: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      /**
       * Formik does a strange thing. When the submit button is clicked before dob is touched, it adds an empty object to the dob touched field.
       * For now, we avoid that by tracking it ourselves
       */
      if (dobTouched === false) {
        formik.setFieldError("dob", "Date of birth is required");
        return;
      }

      if(formik.isValid){
      setLoginFormData(values.email, values.password);
      formik.setSubmitting(true);
      await submit(values);
      }
    },
    validateOnMount: false,
  });
  const { setValue: setDobValue } = formik.getFieldHelpers("dob");
  const [showPassword, setShowPassword] = React.useState(false);
  const [isAddressLoading, setAddressLoading] = React.useState(false);

  const dobChangeHandler = (dayjsDate) => {
    setDobTouched(true);
    setDobValue(dayjsDate.toDate(), true);
  };

  const handleFindAddress = async (postalCode) => {
    setAddressLoading(true);
    try {
      const apiKey = "MUZB4nuIkk6i5XTyv3xVXA42336";

      let data = JSON.stringify({
        all: true,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `https://api.getaddress.io/autocomplete/${postalCode}?api-key=${apiKey}`,
        headers: {
          "Content-Type": "application/json",
          Cookie: "TiPMix=22.905410387410008; x-ms-routing-name=self",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          var addressSuggestions = [];

          if (response.data.suggestions.length > 0) {
            response.data.suggestions.forEach((element) => {
              element.label = element.address;
              addressSuggestions.push({
                value: element.label,
                label: element.address,
              });
            });
            setAddressData({
              ...addressData,
              addressOptions: addressSuggestions,
            });
          } else {
            setShowAddressses(false);
          }
          setAddressLoading(true);
        })
        .catch((error) => {
          setShowAddressses(false);
          console.error(error);
        });
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  };
  const passwordsMismatch =
    formik.touched.verifyPassword &&
    formik.touched.password &&
    formik.values.password !== formik.values.verifyPassword;

  const passwordMismatchMessage = passwordsMismatch
    ? "Passwords do not match"
    : "";

  return (
    <Grid
      onSubmit={formik.handleSubmit}
      component={"form"}
      container
      spacing={3}
    >
      <Grid xs={12} md={6}>
        <TextField
          fullWidth
          id="firstName"
          name="firstName"
          label="First name"
          autoComplete="first-name"
          value={formik.values.firstName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={formik.touched.firstName && formik.errors.firstName}
        />
      </Grid>
      <Grid xs={12} md={6}>
        <TextField
          fullWidth
          id="lastName"
          name="lastName"
          label="Last name"
          autoComplete="last-name"
          value={formik.values.lastName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
        />
      </Grid>
      <Grid xs={12} md={6}>
        <TextField
          fullWidth
          id="email"
          name="email"
          label="Email"
          autoComplete="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
      </Grid>
      <Grid xs={12} md={6}>
        <TextField
          fullWidth
          id="mobile"
          name="mobile"
          label="Mobile"
          autoComplete="mobile"
          value={formik.values.mobile}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.mobile && Boolean(formik.errors.mobile)}
          helperText={formik.touched.mobile && formik.errors.mobile}
        />
      </Grid>
      <Grid xs={12} md={6}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            id="dob"
            name="dob"
            label="Date of birth"
            value={dayjs(formik.values.dob)}
            onChange={dobChangeHandler}
            onBlur={formik.handleBlur}
            format="DD-MM-YYYY"
            slotProps={{
              textField: {
                fullWidth: true,
                helperText: formik.touched.dob && formik.errors.dob,
                error: formik.touched.dob && Boolean(formik.errors.dob),
                inputProps: {
                  style: {
                    border: "none",
                  },
                },
                InputProps: {
                  sx: {
                    border: "none",
                  },
                },
              },
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid xs={12} md={6}>
        <TextField
          fullWidth
          id="occupation"
          name="occupation"
          label="Occupation"
          //autoComplete='first-name'
          value={formik.values.occupation}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.occupation && Boolean(formik.errors.occupation)}
          helperText={formik.touched.occupation && formik.errors.occupation}
        />
      </Grid>
      <Grid xs={12} md={4}>
        <TextField
          fullWidth
          id="postCode"
          name="postCode"
          label="Postcode"
          autoComplete="new-duck"
          value={formik.values.postCode.toUpperCase()}
          onChange={formik.handleChange}
          //onBlur={formik.handleBlur}
          onBlur={() => handleFindAddress(formik.values.postCode)}
          error={formik.touched.postCode && Boolean(formik.errors.postCode)}
          helperText={formik.touched.postCode && formik.errors.postCode}
        />
      </Grid>
      <Grid xs={12} md={8}>
        {/* <TextField
                    fullWidth
                    id="fullAddress"
                    name="fullAddress"
                    label="Full Address"
                    //autoComplete='first-name'
                    value={formik.values.fullAddress}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.fullAddress && Boolean(formik.errors.fullAddress)}
                    helperText={formik.touched.fullAddress && formik.errors.fullAddress}
                /> */}
        <FormControl fullWidth>
          <Autocomplete
            value={formik.values.address1 || ""}
            onChange={(event, newValue) => {
              formik.setFieldValue("address1", newValue ? newValue.value : "");
              //alert(newValue)
            }}
            inputValue={formik.values.address1 || ""}
            onInputChange={(event, newInputValue) => {
              formik.setFieldValue("address1", newInputValue);
            }}
            loading={isAddressLoading}
            loadingText={"Loading addresses.."}
            options={addressData.addressOptions}
            //getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <form autoComplete={"new-goat"}>
                <TextField
                  {...params}
                  label="Full Address"
                  variant="outlined"
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.address1 && Boolean(formik.errors.address1)
                  }
                  helperText={formik.touched.address1 && formik.errors.address1}
                />
              </form>
            )}
            style={{
              "& .MuiOutlinedInputRoot": {
                "&.Mui-focused fieldset": {
                  borderColor: "red", // Custom border color on focus
                },
              },
            }}
          />
        </FormControl>
      </Grid>
      <Grid xs={12} md={6}>
        <TextField
          fullWidth
          type={showPassword ? "text" : "password"}
          id="password"
          name="password"
          label="Password"
          autoComplete="new-password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          inputProps={{
            style: {
              border: "none",
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {showPassword ? (
                  <FaEye
                    onClick={() => setShowPassword(false)}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <FaEyeSlash
                    onClick={() => setShowPassword(true)}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid xs={12} md={6}>
        <TextField
          fullWidth
          type={showPassword ? "text" : "password"}
          id="verifyPassword"
          name="verifyPassword"
          label="Verify password"
          autoComplete="new-password"
          value={formik.values.verifyPassword}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.verifyPassword &&
            
              Boolean(formik.errors.verifyPassword)
          }
          helperText={
            formik.touched.verifyPassword &&
            (formik.errors.verifyPassword)
          }
          inputProps={{
            style: {
              border: "none",
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {showPassword ? (
                  <FaEye
                    onClick={() => setShowPassword(false)}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <FaEyeSlash
                    onClick={() => setShowPassword(true)}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid xs={12}>
        <LoadingButton
          fullWidth
          sx={{
            background: "#278e9c",
            ":hover": {
              background: "#278e9c",
            },
          }}
          loading={formik.isSubmitting}
          color="primary"
          variant="contained"
          type="submit"
          disabled={!formik.isValid && passwordsMismatch}
        >
          Create new profile
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default QuickRegisterForm;
