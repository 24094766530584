import Form from "./quickRegisterForm"
import React, { useState } from "react"
import { useTheme } from "@mui/material"
import NavBarLanding from "../../components/NavBarLanding"
import Divider from "@mui/material/Divider"
import Footer from "../../layout/Footer"
import Stack from "@mui/material/Stack"
import Paper from "@mui/material/Paper"
import Grid from '@mui/material/Unstable_Grid2';
import Container from "@mui/material/Container"
import dayjs from "dayjs"
import AuthService from "../../services/authServices"
import { useNavigate } from "react-router-dom"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { Modal, Box, CircularProgress, Button, Typography, Snackbar, Alert } from '@mui/material';
// TODO: Event tracking
const submitQuickRegistrationData = async (values) => {
    const { firstName, lastName, email, dob, password, mobile, occupation, postCode, address1 } = values
    const data = {
        registrationType: "quickregistered",
        fname: firstName,
        lname: lastName,
        email,
        sourceCountryId: "01",
        dob: dayjs(dob).format("YYYY-MM-DD"),
        toc: "true",
        password,
        verifyPassword: password,
        usernameType: "email",
        mobile: mobile,
        occupation: occupation,
        postcode: postCode,
        address1: address1
    }

    const response = await AuthService.quickRegistration(data)
    if (response.status !== "SUCCESS") {

        const errorMessage  = response.body.result.errors.error.message ? response.body.result.errors.error.message : response.body.result.errors.error[0].message;

        if (errorMessage == "The Remitter Email provided has already been used, please enter a different value"
        ) {
            return { message: "Email already in use. Try another", status: "01" }
        }
        else {
            return { message: errorMessage, status: "99" }
        }
        //throw new Error("Failed to register", response)
    }
    else {
        return { message: "Sign up successful.", status: "00" }
    }
 
    /*
        try {
            const requestBody = {
              "username": email,
              //"emailVerificationCode": emailVerificationCode
            }
      
            const response = await AuthService.confirmRegistration(requestBody);
      
            if (response.status === "SUCCESS") {
            } else {
               
              console.error('Activation failed:', response.message);
            }
          } catch (error) {
    
            console.error('Error:', error);
          } */
}

const handleSubmitData = async (values) => {

    try {
        //{ message :"Sign up successful.", status: "00"}
        const response = await submitQuickRegistrationData(values)

        // TODO: Determine what this is actually used for and if we can do it elsewhere
        localStorage.setItem("username", values.email)
        //localStorage.setItem("password", values.password)
        //localStorage.setItem("uuid", uuid)

        return response
    }
    catch (e) {
        console.error(e)
        return { message: "Sign up failed.", status: "99" }
        //throw e
    }
}

const submitConfirmRegistrationData = async (verificationCode) => {
    try {
        const requestBody = {
            "username": localStorage.getItem("username"),
            "emailVerificationCode": verificationCode
        }

        const response = await AuthService.confirmRegistration(requestBody);

        if (response.status === "SUCCESS") {
        } else {

            console.error('Activation failed:', response.message);
            throw new Error('Activation failed:', response.message);
        }
    } catch (error) {

        console.error('Error:', error);
        throw new Error('Activation failed:', error);

    }
}

const handleSubmitConfirmation = async (verificationCode) => {
    await submitConfirmRegistrationData(verificationCode)

}

const useHandleSubmitConfirmation = () => {
    const navigate = useNavigate()

    return (verificationCode, username, password) => {
        handleSubmitConfirmation(verificationCode)
            .then(() => {
                // const response = await 

                AuthService.login({ username, password }).then((response) => {
                    if (response.status === "SUCCESS") {
                        navigate("/dashboard")
                    }
                }
                )

            })
            .catch((err) => {
                // Failed to quick register
                console.error(err)
                // TODO: What we do?
            })
    }
}



const QuickRegister = () => {

    const theme = useTheme()
    const navigate = useNavigate()

    const handleSubmitConfirmation = useHandleSubmitConfirmation()
    const [showOTPModal, setShowOTPModal] = useState(false);
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [showLoggingYouInLoader, setShowLoggingYouInLoader] = useState(false)
    const [loaderText, setLoaderText] = useState(' Setting up your profile')

    const [showProfileFailureNotification, setShowProfileFailureNotification] = useState(false)
    const [profileFailureNotificationMessage, setProfileFailureNotificationMessage] = useState("Failed to create profile")
    const [showProfileSuccessNotification, setShowProfileSuccessNotification] = useState(false)


    const setLoginFormData = (email, password) => {
        setUsername(email)
        setPassword(password)

    }
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const useHandleSubmit = () => {
        // const navigate = useNavigate()
        return (values) => {
            setShowLoggingYouInLoader(true)
            handleSubmitData(values)
                .then((response) => {
                    //navigate("/confirmation")
                    // FEATURE FLAGE DISABLED OTP MODAL

                    // { message :"Sign up successful.", status: "00"}

                    if (response.status == "00") {
                        setShowProfileSuccessNotification(true)

                        if (process.env.REACT_APP_OTP_MODAL_FEATURE === 'true') {
                            setShowOTPModal(true)
                        }
                        else {
                            setLoaderText('Logging you in')
                            AuthService.login({ "username": values.email, "password": values.password }).then((response) => {
                                if (response.status === "SUCCESS") {
                                    navigate("/dashboard")
                                }
                            }
                            )


                        }
                    }
                    else {
                        setShowLoggingYouInLoader(false)
                        setShowProfileFailureNotification(true)
                        setProfileFailureNotificationMessage(response.message)

                    }

                })
                .catch((err) => {
                    // Failed to quick register
                    console.error(err)
                    // TODO: What we do?
                })
        }
    }
    const handleSubmit = useHandleSubmit()
    const handleClose = () => {
        setShowOTPModal(false);
    };

    const handleResendOTP  = () => {

        const email = localStorage.getItem("username")

        const requestBody = {
            "emailAddress": email,
        }
        AuthService.resendOTP(requestBody)
    };

    return (
        <Stack
            className='bg-light'
            sx={{
                [theme.breakpoints.up('md')]: {
                    height: '100vh',
                },
                [theme.breakpoints.down('md')]: {
                    height: 'fit-content',
                }
            }}
        >

            <Box
                sx={{
                    [theme.breakpoints.down('md')]: {
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1,
                        width: '100%',
                    }
                }}
            >
                <NavBarLanding page="signup" />
            </Box>
            <Divider
                sx={{
                    border: 'none',
                    background: 'transparent',
                    height: '5rem',
                    [theme.breakpoints.up('md')]: {
                        display: 'none'
                    }
                }}
            />
            <Grid
                container
                maxWidth={'lg'}
                spacing={3}
                margin={"auto"}
                direction={"row"}
            >
                <Grid
                    display={"flex"}
                    alignItems={"center"}
                    md={6}>
                    <Container>
                        <h1 className="home-title mb-0"><span style={{ color: "#2e3664", fontWeight: "400" }}>Fast & Secure</span></h1>
                        <h3><span style={{ color: "#268e9c", fontWeight: "200" }}>Send Money Anywhere</span></h3>
                        <div className="home-heading">
                            <h5 className="home-title">Create your Profile!</h5>
                            <p className="">We at Wiremit are thrilled to have you onboard.
                                Your Needs, Your Savings, Crafted in One Unstoppable Service</p>
                        </div>
                    </Container>
                </Grid>
                <Grid
                    padding={3}
                    md={6}
                >
                    <Paper
                        sx={{
                            padding: 4,
                            borderRadius: 8,
                        }}
                    >
                        <Form submit={handleSubmit} setLoginFormData={setLoginFormData} />
                    </Paper>
                </Grid>
            </Grid>
            <Footer />

            <Dialog
                open={showOTPModal}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries(formData.entries());
                        const verificationCode = formJson.verificationCode;
                        handleSubmitConfirmation(verificationCode, username, password);
                        handleClose();
                    },
                }}
            >
                <DialogTitle>Verification</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please enter the verification code sent to you at {localStorage.getItem("username")}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="name"
                        name="verificationCode"
                        label="Verification Code"
                        type="text"
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                <Button sx={{
                        background: "#278e9c",
                        ":hover": {
                            background: "#278e9c"
                        },
                        color: 'white',
                        marginRight:'20%',
                    }} onClick={handleResendOTP}>Resend Code</Button><Button onClick={handleClose}>Cancel</Button>
                    <Button sx={{
                        background: "#278e9c",
                        ":hover": {
                            background: "#278e9c"
                        },
                        color: 'white'
                    }} type="submit">Verify</Button>
                      
                </DialogActions>
            </Dialog>


            <Modal
                open={showLoggingYouInLoader}
                onClose={handleClose}
                aria-labelledby="loading-modal-title"
                aria-describedby="loading-modal-description"
                closeAfterTransition
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    position="fixed"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                    bgcolor="background.paper"
                    boxShadow={24}
                    p={4}
                    borderRadius={1}
                >
                    <CircularProgress />
                    <Typography id="loading-modal-description" sx={{ mt: 2 }}>
                        {loaderText}
                    </Typography>
                </Box>
            </Modal>
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={showProfileSuccessNotification} autoHideDuration={6000} onClose={()=>setShowProfileSuccessNotification(false)}>
                <Alert
                    onClose={()=>setShowProfileSuccessNotification(false)}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Profile succesfully created
                </Alert>
            </Snackbar>

            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={showProfileFailureNotification} autoHideDuration={6000} onClose={()=>setShowProfileFailureNotification(false)} 
             >
                <Alert
                    onClose={()=>setShowProfileFailureNotification(false)}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {profileFailureNotificationMessage}
                </Alert>
            </Snackbar>
        </Stack >
    )
}


export default QuickRegister;