import Box from "@mui/material/Box";
import { useEffect, useRef, useState } from "react";
import { FaBars } from "react-icons/fa";
import logoLanding from "../assets/images/logo-landing.png";
import styles from "../moduleCss/NavBarLanding.module.css";

const NavBar = (props) => {
  const [windowHeight, setWindowHeight] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const menus = useRef();

  const { page } = props

  const toggleMenu = () => {
    menus.current.classList.toggle(styles.show);
  };

  const navBarTop = () => {
    if (window !== undefined) {
      let height = window.scrollY;
      setWindowHeight(height);
    }
  };

  const checkMobile = () => {
    if (window.innerWidth < 992) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", navBarTop);
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => {
      window.removeEventListener("scroll", navBarTop);
      window.removeEventListener("resize", checkMobile);
    };
  }, [isMobile]);

  return (
    <Box
      id="header"
      component={"header"}
      className={`${styles["header-section-landing"]} ${windowHeight > 50
          ? `${styles["animated-landing"]} ${styles["fadeInDown"]} ${styles["header-fixed"]}`
          : ""
        }`}
    >
      <div className={styles.overlayLanding}>
        <div className={styles.containerLanding}>
          <div
            className={`${styles["row-landing"]} ${styles["d-flex-landing"]} ${styles["header-area"]}`}
          >
            <nav
              className={`${styles["navbar-landing"]} ${styles["navbar-expand-lg"]} ${styles["navbar-light-landing"]}`}
            >
              <a
                className={`${styles["navbar-brand-landing"]} ${styles["link-landing"]} `}
                href="/"
              >
                <img
                  src={logoLanding}
                  className={styles.logo}
                  alt="logo"
                  width={250}
                />
              </a>
              <button
                className={`${styles["navbar-toggler"]}`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbar-content"
                onClick={toggleMenu}
              >
                <i>
                  <FaBars />
                </i>
              </button>
              <div
                className={`${styles["collapse"]} ${styles["navbar-collapse"]} ${styles["justify-content-end"]}`}
                id="navbar-content"
                ref={menus}
              >
                <div
                  className={`${styles["right-area"]} ${styles["header-action"]} ${styles["d-flex"]} ${styles["align-items-center"]} ${styles["max-un"]} ${styles["menu-container"]}`}
                >
                  <a
                    href="https://www.wiremit.money/help-center"
                    className={styles.cmnBtn}
                    style={{ backgroundColor: "Transparent" }}
                  >
                    Help
                  </a>
                  {page && page === "login" &&
                    <a
                      href="/#/quick-register"
                      className={styles.cmnBtn}
                      onClick={window.fbq('track', 'Calculator/Pay/Signup', {
                        status: "Success",
                        detail: ""
                      })}
                    >
                      Sign Up
                    </a>}
                  {page && page === "signup" &&
                    <a
                      href="/#/"
                      className={styles.cmnBtn}
                    >
                      Login
                    </a>}
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default NavBar;
