import  { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const FacebookPixelEvents = () => {
  const location = useLocation();

  useEffect(() => {
    import("react-facebook-pixel")
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init("2034724323565167");
        ReactPixel.pageView();
        
      });
  }, [location]);

  return null;
};
