import React from 'react';
import NavbarInternal from '../../layout/NavbarInternal';
//import Register from './register';
import RegisterCompleteProfile from './registerCompleteProfile';


const signupCompleteProfile = () => {
    return (
        <React.Fragment>
            <NavbarInternal />
            <RegisterCompleteProfile />
        </React.Fragment>
    )
}

export default signupCompleteProfile;