import React, { useEffect, useState } from "react";
import { FaEquals, FaPlus } from "react-icons/fa";
import "./CalculatorForm.css";

const CurrencyConvertor = () => {
  //const defaultExchangeRate = { GBPUSD: 1.217 };
  const [defaultExchangeRate, setDefaultExchangeRate] = useState({
    GBPUSD: 1.235,
  });
  const commissionRate = 0; // Commission rate: 0%
  const fixedFee = 2.5; // Fixed fee: 2.50 GBP
  const [selectedCurrencyPair, setSelectedCurrencyPair] = useState("GBPUSD"); // Default currency for receiver
  const [amountToSend, setAmountToSend] = useState("100.00"); // Default amount to send

  const [amountToSendUpdated, setAmountToSendUpdated] = useState(0);
  const [recipientAmount, setRecipientAmount] = useState("0"); // Default amount to receive
  const [recipientAmountUpdated, setRecipientAmountUpdated] = useState(0);
  const [showCalculation, setShowCalculation] = useState(true); // State to toggle visibility of calculation dropdown
  const [shouldRoundAmountToSend, setShouldRoundAmountToSend] = useState(false); // Flag to determine rounding for amount to send
  const [shouldRoundRecipientAmount, setShouldRoundRecipientAmount] =
    useState(true); // Flag to determine rounding for recipient amount

  const handleCurrencyPairChange = (currency) => {
    setSelectedCurrencyPair(currency);
  };

  const updateSenderAmount = (event) => {
    let value = event.target.value;
    const senderCurrency = "GBP"; //value.substring(1, 4); // Extract sender currency code from input value
    const recipientCurrency = "USD"; //amountToSend.substring(1, 4); // Extract currency code from amountToSend
    const updatedCurrencyPair = senderCurrency + recipientCurrency; // Construct new currency pair
    setSelectedCurrencyPair(updatedCurrencyPair); // Update selected currency pair

    value = value.replace(/[^0-9.]/g, "");

    if (value > 9999999) {
      value = 9999999;
    }

    setAmountToSend(value);
    setAmountToSendUpdated(Math.random());
  };

  const updateRecipientAmount = (event) => {
    let value = event.target.value;
    const senderCurrency = "GBP"; //value.substring(1, 4); // Extract sender currency code from input value
    const recipientCurrency = "USD"; //amountToSend.substring(1, 4); // Extract currency code from amountToSend
    const updatedCurrencyPair = senderCurrency + recipientCurrency; // Construct new currency pair
    setSelectedCurrencyPair(updatedCurrencyPair); // Update selected currency pair

    value = value.replace(/[^0-9.]/g, "");

    if (value > 9999999) {
      value = 9999999;
    }

    setRecipientAmount(value);
    setRecipientAmountUpdated(Math.random());
  };

  let fee = "0";
  if (amountToSend && selectedCurrencyPair in defaultExchangeRate) {
    // Calculate fee (sum of commission and fixed fee)
    fee = fixedFee.toFixed(2);
  }

  /*let roundedAmountToSend =
    (shouldRoundAmountToSend ? parseFloat(amountToSend).toFixed(0) : amountToSend) || 12;
  if(isNaN(roundedAmountToSend) || roundedAmountToSend < (10 / defaultExchangeRate["GBUSDP"]) ){
    roundedAmountToSend = (10 / defaultExchangeRate["GBUSDP"])
  }  
  */
  /*let roundedRecipientAmount =
    (shouldRoundRecipientAmount
      ? parseFloat(recipientAmount).toFixed(0)
      : recipientAmount) || 1; */

  useEffect(() => {
    const fetchExchangeRate = async () => {
      try {
        const response = await fetch(
          "https://api.wiremit.money/api/v1/rate/getRate?sourceCountryIsoCode=GB&sourceCurrency=GBP&destinationCountryIsoCode=ZW&destinationCurrency=USD"
        );
        const data = await response.json();

        const rate = parseFloat(data.value);

        setDefaultExchangeRate({ GBPUSD: rate });
      } catch (error) {
        console.error("Error fetching exchange rate:", error);
      }
    };

    fetchExchangeRate();
  }, []);

  useEffect(() => {
    // Calculate initial values on component mount
    const senderCurrency = "GBP";
    const recipientCurrency = "USD";
    const updatedCurrencyPair = senderCurrency + recipientCurrency;
    const exchangeRateWithCommission =
      defaultExchangeRate[updatedCurrencyPair] * (1 + commissionRate);

    const initialRecipientAmount = (
      parseFloat(amountToSend) * exchangeRateWithCommission
    ).toFixed(2);
    setRecipientAmount(initialRecipientAmount);
  }, [defaultExchangeRate]); // Empty dependency array triggers the effect only once on mount

  

 const updateAmountsOnSendAmountChanged = () => {
  let workingAmountToSend = amountToSend;
  if (
    amountToSend < 10 / defaultExchangeRate.GBPUSD ||
    isNaN(amountToSend)
  ) {
    workingAmountToSend = 10 / defaultExchangeRate.GBPUSD;
  }

  let tempRecipentAmount = Math.floor(
    workingAmountToSend * defaultExchangeRate.GBPUSD
  );
  // step 1
  let tempAmountToSend = tempRecipentAmount / defaultExchangeRate.GBPUSD;

  setAmountToSend(tempAmountToSend.toFixed(2));
  setRecipientAmount(tempRecipentAmount.toFixed(2));
 }

 const onSendAmountEventBlur = () => {
  updateAmountsOnSendAmountChanged()
};

  useEffect(() => {
    // backulate
    if (amountToSendUpdated == 0) return;

    const timeoutId = setTimeout(() => {

      updateAmountsOnSendAmountChanged();
    }, 1500);

    return () => clearTimeout(timeoutId);
  }, [amountToSendUpdated]);


  const updateAmountsOnRecipientAmountChanged = () => {
    let tempRecipentAmount = Math.floor(recipientAmount);
    if (tempRecipentAmount < 10 || isNaN(tempRecipentAmount)) {
      tempRecipentAmount = 10;
    }
    // step 1
    let tempAmountToSend = tempRecipentAmount / defaultExchangeRate.GBPUSD;

    setAmountToSend(tempAmountToSend.toFixed(2));
    setRecipientAmount(tempRecipentAmount.toFixed(2));
  }

  useEffect(() => {
    // backulate
    if (recipientAmountUpdated == 0) return;

    const timeoutId = setTimeout(() => {
      updateAmountsOnRecipientAmountChanged()
    }, 1500);

    return () => clearTimeout(timeoutId);
  }, [recipientAmountUpdated]);

  /* useEffect(() => {
    const timeoutId = setTimeout(() => {
      // Recalculate recipient amount if amountToSend is rounded
      if (shouldRoundAmountToSend) {
        const senderCurrency = "GBP";
        const recipientCurrency = "USD";
        const updatedCurrencyPair = senderCurrency + recipientCurrency;
        const exchangeRateWithCommission =
          defaultExchangeRate[updatedCurrencyPair] * (1 + commissionRate);

        const recalculatedRecipientAmount = (
          parseFloat(roundedAmountToSend) /
          (1 / exchangeRateWithCommission)
        ).toFixed(2);
        setRecipientAmount(recalculatedRecipientAmount);
      }
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, [shouldRoundAmountToSend, roundedAmountToSend]); */

  const roundedAmountToSend =
    (shouldRoundAmountToSend
      ? parseFloat(amountToSend).toFixed(0)
      : amountToSend) || 0;

  return (
    <div
      className="send-money"
      style={{
        backgroundColor: "rgba(0, 0, 0, 0)",
        borderTopLeftRadius: "16px",
        borderTopRightRadius: "16px",
      }}
    >
      <div
        style={{
          backgroundColor: "rgba(255, 255, 255, 1)",
          borderTopLeftRadius: "16px",
          borderTopRightRadius: "16px",
          borderBottomRightRadius: "16px",
          borderBottomLeftRadius: "16px",
        }}
      >
        <div
          style={{
            background: "linear-gradient(90deg, #345d92 0%, #1c2452 100%)",
            padding: "10px",
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
            color: "white",
            textAlign: "center",
          }}
        >
          <div
            style={{
              fontSize: "0.8rem",
              marginBottom: "8px",
              fontWeight: "bold",
            }}
          >
            Exchange Rate
          </div>

          <div style={{ fontWeight: "bold", fontSize: "1.3rem" }}>
            1 GBP = {defaultExchangeRate[selectedCurrencyPair].toFixed(3)}{" "}
            {/* {selectedCurrencyPair} */} USD
          </div>
        </div>

        <div style={{ borderRadius: "16px" }}>
          <div
            className="currency-area"
            style={{ padding: "20px", margin: "20px" }}
          >
            <div
              style={{
                textAlign: "left",
              }}
            >
              You send
              <input
                autoComplete="off"
                id="receive-field-container-amount"
                placeholder="-"
                type="decimal"
                inputMode="numeric"
                pattern="[0-9]*"
                value={`🇬🇧 GBP ${amountToSend}`}
                onChange={updateSenderAmount}
                onBlur={onSendAmountEventBlur}
              />
            </div>
          </div>

          <div
            className="currency-area"
            style={{ padding: "20px", margin: "20px" }}
          >
            <div
              style={{
                textAlign: "left",
              }}
            >
              They get
              <input
                autoComplete="off"
                id="receive-field-container-amount"
                placeholder="-"
                type="decimal"
                inputMode="numeric"
                pattern="[0-9]*"
                value={`🇿🇼 USD ${recipientAmount}`}
                onChange={updateRecipientAmount}
                onBlur={updateAmountsOnRecipientAmountChanged}
              />
            </div>
          </div>
          <div
            className="calculation"
            style={{ padding: "0px 0px 5px 5px", margin: "20px" }}
          >
            <div className="head-area">
              {showCalculation && (
                <div className="calculation-dropdown">
                  <div className="single-area d-flex align-items-center">
                    <div className="left-area">
                      <i>
                        <FaPlus />
                      </i>
                      <span className="mdr">{fee} GBP</span>
                    </div>
                    <div className="right-area">
                      <span className="mdr">Our Fee</span>
                    </div>
                  </div>
                  <div className="single-area d-flex align-items-center">
                    <div className="left-area">
                      <i>
                        <FaEquals />
                      </i>
                      <span className="mdr">
                        {(parseFloat(roundedAmountToSend) + parseFloat(fee)).toFixed(
                          2
                        )}{" "}
                        GBP
                      </span>
                    </div>
                    <div className="right-area">
                      <span className="mdr">You Pay</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="btn-area mt-20" style={{ margin: "5%" }}>
            <button
              className="cmn-btn w-100 shimmer-button"
              onClick={() => {
                window.location.href = "https://secure.wiremit.money/";
              }}
            >
              Pay {(parseFloat(roundedAmountToSend) + parseFloat(fee)).toFixed(2)} GBP
            </button>
            <br />
          </div>
          <br />
        </div>
      </div>
    </div>
  );
};

export default CurrencyConvertor;
