/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap';
import './styles.css'
// eslint-disable-next-line no-unused-vars
import ReactCountryFlag from 'react-country-flag';

const Section = () => {


  const [orderId, setOrderId] = useState('');
  const [status, setStatus] = useState('');

  useEffect(() => {
    const hashParams = new URLSearchParams(
      decodeURIComponent(window.location.hash.slice(window.location.hash.indexOf('?') + 1))
    );
  
    //console.debug('Hash Params:', hashParams.toString());
    //console.debug('Order ID from Hash Params:', hashParams.get('order_id'));
    //console.debug('Status from Hash Params:', hashParams.get('status'));
  
    setOrderId(hashParams.get('order_id'));
    setStatus(hashParams.get('status'));
  }, []);

  // eslint-disable-next-line no-unused-vars
  const transactionDetails = JSON.parse(localStorage.getItem('transactionProcess'))

  return (
    <React.Fragment>
      <section className="home-1 bg-home d-flex bg-light" id="home" style={{ height: 'auto' }}>
        <Container>
          <Row className="justify-content-center">
            <Col md={12} className=" mb-5 mt-0 mt-md-5 pt-0 pt-md-5">
              <div className="home-heading">
                <h3 className="text-center home-title">
                  <span style={{ color: "#2e3664", fontWeight: "400" }}>Transaction Completed</span>
                  <br />
                </h3>
                <p className="text-center text-muted para-desc" style={{ fontSize: "1em", fontWeight: "300" }}>
                  The payment has been completed with the following details.
                </p>
                <div className=" glassmorphic-container-curve text-center mt-5  transaction-success ">

                  <div className="dummy-positioning d-flex mb-5 mt-3">
                    <div className="success-icon">
                      <div className="success-icon__tip"></div>
                      <div className="success-icon__long"></div>
                    </div>
                  </div>

                  <p style={{ fontSize: "1.2em" }}>
                    <Row className="mb-1">
                      <Col className=" con-value">Transaction Reference:</Col>
                      <Col className="con-title"><b>{orderId}</b></Col>
                    </Row>
                    <Row className="mb-1">
                      <Col className=" con-value">Transaction Status:</Col>
                      <Col className="con-title"><b>{status}</b></Col>
                    </Row>

                    {/* 
                    <>
                      <Row className="mb-1">
                        <Col className=" con-value">Transaction Reference:</Col>
                        <Col className="con-title"><b>{transactionDetails.trans_ref}</b></Col>
                      </Row>
                    
                      <Row className="mb-1">
                        <Col className="con-title">Beneficiary Name</Col>
                        <Col className="con-value"><b>{transactionDetails.benef_name}</b></Col>
                      </Row>

                      <Row className="mb-1">
                        <Col className="con-title">Beneficiary Mobile</Col>
                        <Col className="con-value"><b>{transactionDetails.benef_mobile}</b></Col>
                      </Row>

                      <Row className="mb-1">
                        <Col className="con-title">Beneficiary Email</Col>
                        <Col className="con-value"><b>{transactionDetails.benef_email}</b></Col>
                      </Row>

                      <Row className="mb-1">
                        <Col className="con-title">Receive Amount</Col>
                        <Col className="con-value"><b>{transactionDetails.receive_currency} {transactionDetails.receive_amount}</b></Col>
                      </Row>

                      <Row className="mb-1">
                        <Col className="con-title">Transaction Type</Col>
                        <Col className="con-value"><b>{transactionDetails.trans_type}</b></Col>
                      </Row>
                      <hr className='mt-4 mb-4' />
                      <Row className="mb-1">
                        <Col className="con-title">Receive Country</Col>
                        <Col className="con-value">
                          <ReactCountryFlag
                            countryCode={
                              transactionDetails.receive_country
                            }
                            svg
                            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                            cdnSuffix="svg"
                            title={transactionDetails.receive_country}
                          />

                        </Col>
                      </Row>
                      <Row className="mb-1">
                        <Col className="con-title">Source of Income</Col>
                        <Col className="con-value"><b>{transactionDetails.source_of_income}</b></Col>
                      </Row>
                      <Row className="mb-1">
                        <Col className="con-title">Send Amount</Col>
                        <Col className="con-value"><b>{transactionDetails.send_currency} {transactionDetails.send_amount}</b></Col>
                      </Row>
                      <Row className="mb-1">
                        <Col className="con-title">Total Charges</Col>
                        <Col className="con-value"><b>{transactionDetails.send_currency} {transactionDetails.total_charges}</b></Col>
                      </Row>
                      <Row className="mb-1">
                        <Col className="con-title">Total Paid</Col>
                        <Col className="con-value"><b>{transactionDetails.send_currency} {transactionDetails.remitter_pay}</b></Col>
                      </Row>
                    </> 
                    */}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Section;