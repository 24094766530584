import React, { useEffect, useState } from 'react';
import Routes from "./routes";


function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);


  const handleLogout = () => {
    // Perform any logout actions (e.g., clear authentication token)
    setIsLoggedIn(false);
    alert('logout')
    localStorage.clear();
  };

  // Set the initial timer duration and inactivity threshold
  const initialTimerDuration = 0.5 * 60 * 1000; // 5 minutes
  const inactivityThreshold = 0.5 * 60 * 1000; // 30 minutes

  // eslint-disable-next-line no-unused-vars
  const [timerDuration, setTimerDuration] = useState(initialTimerDuration);

  useEffect(() => {

    if (localStorage.getItem('sessionTokensessionToken')) {
      setIsLoggedIn(true);
      alert('logged in')
    };

    let timerId;

    const resetTimer = () => {
      clearTimeout(timerId);
      setTimerDuration(initialTimerDuration);
      startTimer();
    };

    const startTimer = () => {
      timerId = setTimeout(() => {
        // Log out the user after inactivity threshold is reached
        handleLogout();
      }, inactivityThreshold);
    };

    const handleUserActivity = () => {
      resetTimer();
    };

    // Check if the user is logged in before setting up the timer
    if (isLoggedIn) {
      // Set up event listeners for user activity
      window.addEventListener('mousemove', handleUserActivity);
      window.addEventListener('keydown', handleUserActivity);

      // Start the timer on mount
      startTimer();

      // Clean up event listeners on unmount
      return () => {
        clearTimeout(timerId);
        window.removeEventListener('mousemove', handleUserActivity);
        window.removeEventListener('keydown', handleUserActivity);
      };
    }
  }, [initialTimerDuration, inactivityThreshold, isLoggedIn]);


  return <Routes />
}

export default App;
