import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import NavBarLanding from '../../components/NavBarLanding';
import Footer from '../../layout/Footer';
import Section from './section';

const Home = () => {
  const theme = useTheme();

  return (
    <Stack
      width={'100%'}
      className='bg-light'
      sx={{
        [theme.breakpoints.up('md')]: {
          height: '100vh',
          justifyContent: 'space-between'
        },
        [theme.breakpoints.down('md')]: {
          height: 'fit-content',
          gap: 3,
        }
      }}
    >
      <Box
        sx={{
          [theme.breakpoints.down('md')]: {
            position: 'fixed',
            top: 0,
            left: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1,
            width: '100%',
          }
        }}
      >
        <NavBarLanding page="login" />
      </Box>
      <Divider
        sx={{
          border: 'none',
          background: 'transparent',
          height: '5rem',
          [theme.breakpoints.up('md')]: {
            display: 'none'
          }
        }}
      />
      <Section />
      <Footer />
    </Stack>
  )
}

export default Home;