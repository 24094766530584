import { PrimeReactProvider } from "primereact/api";
import React from "react";
import { createRoot } from 'react-dom/client';
import "react-dropdown/style.css";
import { HashRouter } from "react-router-dom";
import App from "./App";
import "./integrations/datadog";

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
import TagManager from "react-gtm-module";
import { FacebookPixelEvents } from "./components/facebookPixels";

const tagManagerArgs = {
  gtmId: "AW-11464517865",
  dataLayer: {
    userId: "001",
    userProject: "wiremit-international",
  },
};

TagManager.initialize(tagManagerArgs);

const container = document.getElementById('root');
const root = createRoot(container);

async function enableMocking() {
  if ((process.env.REACT_APP_MOCK) !== "1") {
    return
  }

  const { worker } = await import('./mocks/browser')

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start()
}

enableMocking().then(() => {
  root.render(
    <HashRouter>
      <PrimeReactProvider value={{ appendTo: "self" }}>
        <App />
        <FacebookPixelEvents />
      </PrimeReactProvider>
    </HashRouter>
  );
})