import axios from 'axios';
import { getEndpoint } from './endpoint';

const ackWiremitInternationalServiceEndpoint = getEndpoint();

const config = {
  baseURL: `${ackWiremitInternationalServiceEndpoint}`,
  headers: {
    'Content-Type': 'application/json',
  },
};

const initiateRegistrationEndpoint = '/user/initiateRegistration';
//initiate the registration data
async function initiateRegistration(data) {

  const requestBody = {
    "operation": "login",
    "accessToken": "",
    "requestBody": data
  }

  try {
    const res = await axios.post(initiateRegistrationEndpoint, requestBody, config);
    return res.data;
  } catch (err) {
    console.error(err);
    return { success: false, message: 'Connection failed. Please try again in a moment.' };
  }
}

const quickRegistrationEndpoint = '/user/quickRegistration';

async function quickRegistration(data) {

  const requestBody = {
    "operation": "login",
    "accessToken": "",
    "requestBody": data
  }

  try {
    const res = await axios.post(quickRegistrationEndpoint, requestBody, config);
    return res.data;
  } catch (err) {
    console.error(err);
    return { success: false, message: 'Connection failed. Please try again in a moment.' };
  }
}

const getLiveIDVerificationEndpoint = '/user/getLiveIDVerification';
//returns user data from GBG for confirmarion
async function getLiveIDVerification(uuid) {
  try {
    const requestBody = {
      "operation": "live id verfirication",
      "accessToken": "",
      "requestBody": {
        "sessionId": uuid
      }
    }
    const res = await axios.post(getLiveIDVerificationEndpoint, requestBody, config);
    return res.data;
  } catch (err) {
    console.error(err);
    return { success: false, message: 'Connection failed. Please check your connection.' };
  }
}

//hit this will all the data
const registerEndpoint = '/user/registration';
async function register(data) {

  const requestBody = {
    "operation": "login",
    "accessToken": "",
    "requestBody": data
  }

  try {
    const res = await axios.post(registerEndpoint, requestBody, config);
    return res.data;
  } catch (err) {
    console.error(err);
    return { success: false, message: 'Connection failed. Please try again in a moment.' };
  }
}

const confirmRegistrationEndpoint = '/user/confirmRegistration';
async function confirmRegistration(data) {
  try {
    const requestBody = {
      "operation": "confirmRegistration",
      "accessToken": "",
      "requestBody": data
    }
    const res = await axios.post(confirmRegistrationEndpoint, requestBody, config);
    return res.data;
  } catch (err) {
    console.error(err);
    return { success: false, message: 'Connection failed. Please check your connection.' };
  }
}

const resetPasswordEndpoint = '/user/forgotPassword';
async function resetPassword(data) {
  try {
    const requestBody = {
      "operation": "resetPassword",
      "accessToken": "",
      "requestBody": data
    }
    const res = await axios.post(resetPasswordEndpoint, requestBody, config);
    return res.data;
  } catch (err) {
    console.error(err);
    return { success: false, message: 'Connection failed. Please try again in a moment.' };
  }
}

const resendOTPEndpoint = '/user/resendRegistrationOTP';
async function resendOTP(data) {
  try {
    const requestBody = {
      "operation": "resendOTP",
      "accessToken": "",
      "requestBody": data
    }
    const res = await axios.post(resendOTPEndpoint, requestBody, config);
    return res.data;
  } catch (err) {
    console.error(err);
    return { success: false, message: 'Connection failed. Please try again in a moment.' };
  }
}

const setNewPasswordEndpoint = '/user/changePassword';
async function setNewPassword(data) {
  try {
    const requestBody = {
      "operation": "setPassword",
      "accessToken": "",
      "requestBody": data
    }
    const res = await axios.post(setNewPasswordEndpoint, requestBody, config);
    return res.data;
  } catch (err) {
    console.error(err);
    return { success: false, message: 'Connection failed. Please try again later.' };
  }
}

const loginEndpoint = '/user/login';
async function login(data) {
  try {
    const requestBody = {
      "operation": "login",
      "accessToken": "",
      "requestBody": data
    }
    const res = await axios.post(loginEndpoint, requestBody, config);

    return getProfile(data, res.data.responseBody);
  } catch (err) {
    console.error(err);
    return err;
  }
}

const getProfileEndpoint = '/user/getProfile';
async function getProfile(loginData, responseBody) {
  try {
    if (responseBody.status === "SUCCESS") {
      const requestBody = {
        "operation": "getProfile",
        "accessToken": "",
        "requestBody": {
          "username": loginData.username,
          "sessionToken": responseBody.session_token[0]
        }

      }

      const res = await axios.post(getProfileEndpoint, requestBody, config);
      res.data.responseBody.sessionToken = responseBody.session_token[0];
      localStorage.setItem('sessionToken', responseBody.session_token[0]);
      localStorage.setItem('ratesSessionToken', responseBody.session_token[0]);
      localStorage.setItem('username', loginData.username);
      localStorage.setItem('user', (JSON.stringify(res.data.responseBody.result.remitter)));
      if(res.data.responseBody.uuid !== 'null')
        localStorage.setItem('uuid',res.data.responseBody.uuid)

      localStorage.setItem('poaUploadStatus',res.data.responseBody.poaUploadStatus)
      return res.data.responseBody;
    }

    return responseBody;

  } catch (err) {
    console.error(err);
    return err;
  }
}

const uploadKycEndpoint = '/user/kyc';
async function uploadKyc(base64String) {
  try {

    const requestBody = {
      "operation": "Upload KYC Video",
      "accessToken": "",
      "requestBody": {
        "username": localStorage.getItem('username'),
        "sessionToken": localStorage.getItem('sessionToken'),
        "kyc_video": base64String,
        "filename": localStorage.getItem('sessionToken').substring(1, 8) + ".webm"
      }
    }
    const res = await axios.post(uploadKycEndpoint, requestBody, config);

    return res.data.responseBody;

  } catch (err) {
    console.error(err);
    return err;
  }
}

const getKycEndpoint = '/user/kyc/video';
async function getKyc() {
  try {

    const requestBody = {
      "operation": "Upload KYC Video",
      "accessToken": "",
      "requestBody": {
        "username": localStorage.getItem('username'),
        "sessionToken": localStorage.getItem('sessionToken'),
      }
    }
    const res = await axios.post(getKycEndpoint, requestBody, config);

    return res.data.responseBody;

  } catch (err) {
    console.error(err);
    return err;
  }
}

const uploadPOAEndpoint = '/user/updateProfile';
async function uploadPOA(base64String) {
  try {

    const requestBody = {
      "operation": "Upload POA ",
      "accessToken": "",
      "requestBody": {
        "username": localStorage.getItem('username'),
        "sessionToken": localStorage.getItem('sessionToken'),
        "id2_scan": base64String,
      }
    }
    const res = await axios.post(uploadPOAEndpoint, JSON.stringify(requestBody), config);

    return res.data.responseBody;

  } catch (err) {
    console.error(err);
    return err;
  }
}
 
const updateProfileCompleteEndpoint = '/user/updateProfileComplete';

async function updateProfile(data) {
  try {

    const requestBody = {
      "operation": "update Profile",
      "accessToken": "",
      "requestBody": data
    }
    const res = await axios.post(updateProfileCompleteEndpoint, JSON.stringify(requestBody), config);

    return res.data.responseBody;

  } catch (err) {
    console.error(err);
    return err;
  }
}

const getRemitterUISettingsEndpoint = '/user/getRemitterUISettings';
async function getRemitterUISettings(sourceCountry) {

  const requestBody = {
    "operation": "get ui settings",
    "requestBody": {
      "sourceCountryId": sourceCountry,
      "registrationType": "registered"
    }
  }

  try {
    const res = await axios.post(getRemitterUISettingsEndpoint, requestBody, config);
    return res.data;
  } catch (err) {
    console.error(err);
    return { success: false, message: 'Connection failed. Please check your connection.' };
  }
}



const AuthService = {
  register,
  registerEndpoint,
  confirmRegistration,
  login,
  uploadKyc,
  uploadPOA,
  getKyc,
  getRemitterUISettings,
  getLiveIDVerification,
  resetPassword,
  setNewPassword,
  initiateRegistration,
  initiateRegistrationEndpoint,
  quickRegistration,
  updateProfile,
  resendOTP,
  getProfile

};

export default AuthService;
