import { datadogRum } from '@datadog/browser-rum';

const datadogEnv = process.env['REACT_APP_DD_ENV']

if (['staging', 'production'].includes(datadogEnv)) {
    datadogRum.init({
        applicationId: process.env['REACT_APP_DD_APPLICATION_ID'],
        clientToken: process.env['REACT_APP_DD_CLIENT_TOKEN'],
        // `site` refers to the Datadog site parameter of your organization
        // see https://docs.datadoghq.com/getting_started/site/
        site: 'datadoghq.eu',
        service: 'international',
        env: datadogEnv,
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0', 
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'allow',
    });
}
