import Box from '@mui/material/Box';
import React from 'react';
import {
    Col,
    Container,
    Row,
} from 'react-bootstrap';

const Footer = () => {
    return (
        <Box
            id="footer"
            className="bottom-footer py-4 glassmorphic-container"
            sx={{
                background: "rgb(248,249,252)",
            }}
        >
            <Container>
                <Row className="justify-content-center">
                     
                        <p className="mb-0 text-center text-muted">
                            {new Date().getFullYear()} © Wiremit Ltd is licensed as a Payment Services Directive Agent (PSD Agent) of Transfa Pay Ltd (FRN 944767), authorised & regulated by the Financial Conduct Authority (FCA) under, Firm Reference: 
                            <a href="https://register.fca.org.uk/s/firm?id=0014G00003CFn78QAD ">1001697</a>
                        </p>
                     
                </Row>
            </Container>
        </Box>
    )
}

export default Footer;