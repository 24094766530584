import React from 'react';
import { Route, Routes } from 'react-router-dom';
import authProtectedRoutes from './allRoutes';

const Index = () => {
  return (
    <Routes>
        {authProtectedRoutes.map((route) => (
          <Route
            path={route.path}
            element={route.component}
            key={route.path}
            exact={true}
          />
        ))}
    </Routes>
  );
};

export default Index;
