/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// import Image
import logoLight from "../assets/images/logo-light.png";
import logoDark from "../assets/images/logo-dark.png";

const NavbarInternal = () => {
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState("Home");

  const [navClass, setnavClass] = useState("");

  // navbar Scroll

  useEffect(() => {
    if (!localStorage.getItem("sessionToken")) {
      // window.location.reload();
      navigate("/");
    }
    window.addEventListener("scroll", scrollNavigation, true);
  });

  function scrollNavigation() {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 50) {
      setnavClass("nav-sticky");
    } else {
      setnavClass("");
    }
  }

  function logout() {
    localStorage.clear();
  }

  // toggle

  const navMenuRef = useRef(null);

  const toggleNavMenu = () => {
    navMenuRef.current.classList.toggle("collapse");
  };

  return (
    <React.Fragment>
      <div
        data-bs-spy="scroll"
        data-bs-target="#navbar"
        data-bs-offset="61"
        data-bs-smooth-scroll="true"
        className="scrollspy-example-2"
      >
        <nav
          className={`navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-light ${navClass}`}
          id="navbar"
        >
          <Container fluid>
            <Navbar.Brand href="/#/dashboard" className="logo text-uppercase">
              <img src={logoLight} className="logo-light" alt="" height="50" />
              <img src={logoDark} className="logo-dark" alt="" height="50" />
            </Navbar.Brand>

            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={toggleNavMenu}
            >
              <span className="mdi mdi-menu"></span>
            </button>

            <div
              ref={navMenuRef}
              className="collapse navbar-collapse"
              id="navbarCollapse"
            >
              <div className="ms-auto">
                {/* <Link to="/dashboard" className="btn m-2">Home</Link> */}
                <Link to="/" className="btn bg-primary" onClick={logout}>
                  Logout
                </Link>
              </div>
            </div>
          </Container>
        </nav>
      </div>
    </React.Fragment>
  );
};

export default NavbarInternal;
