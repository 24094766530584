import React from 'react'
import AuthService from '../../services/authServices';
import Link from "@mui/material/Link"
import { Typography } from '@mui/material';

const getLiveVerifyLink = async (sessionId) => {
    const responseUiSettings = await AuthService.getRemitterUISettings("01");
    return responseUiSettings.responseBody.result.live_id_verification_url + "?session_id=" + sessionId;
}

const useGetVerifyLink = (sessionId) => {
    const [loading, setLoading] = React.useState(true);
    const [liveVerifyUrl, setLiveVerifyUrl] = React.useState("");

    React.useEffect(() => {
        getLiveVerifyLink(sessionId)
            .then((liveVerifyUrl) => {
                setLiveVerifyUrl(liveVerifyUrl);
                setLoading(false);
            }).catch((error) => {
                console.error(error);
                setLoading(false);
            })
    }, [sessionId])

    return {
        loading,
        liveVerifyUrl
    }
}

// Separate verify screen to enable an async registration process
const Verify = () => {
    // TODO: Get sessionId
    // const { loading, liveVerifyUrl } = useGetVerifyLink("123456");

    // if (loading) {
    //     return <div>Loading...</div>
    // }

    // TODO: Iframe to display the KYC verification
    return (
        <>
            <Typography variant='h2'>Example Iframed site</Typography>
            <iframe
                src={"https://secure.wiremit.money/"}
                title="KYC Verification"
                style={{
                    width: "100%",
                    height: "100vh",
                    border: "none"
                }}
            />
        </>
    )
}

export default Verify