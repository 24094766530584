import React from 'react'
import Footer from '../layout/Footer'

const index = ({ children }) => {
    return (
        <>
            {children}
            <Footer />
        </>
    )
}

export default index