import AOS from 'aos';
import 'aos/dist/aos.css';
import { Message } from 'primereact/message';
import { Skeleton } from 'primereact/skeleton';
import { Toast } from 'primereact/toast';
import React, { useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Row
} from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from 'react-router-dom';
import Navbar1 from "../../layout/Navbar";
import AuthService from '../../services/authServices';

AOS.init({
  duration: 1800,
});

const Section = () => {

  const LoadingSkeleton = () => {
    return (
      <div className="border-round border-1 surface-border mt-2 surface-card">
        <Skeleton width="80%" height="45px"></Skeleton>
      </div>)
  }

  const navigate = useNavigate();
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);

  const [emailVerificationCode, setemailVerificationCode] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [dob, setDob] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [displayVerificationCodeField, setDisplayVerificationCodeField] = useState(false);
  const displayError = false
  const displayErrorMessage = "Enter correct DOB in the format YYYYMMDD eg 20050101";

  const [passwordValidationRules, setPasswordValidationRules] = useState({});

  const sendNewPassword = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      const requestBody = {
        "username": emailAddress,
        "forgot_password_token": emailVerificationCode,
        "new_password": newPassword
      }

      const response = await AuthService.setNewPassword(requestBody);

      if (response.status === "SUCCESS") {
        toast.current.show({
          severity: 'success',
          summary: 'Password Reset successful',
          detail: response.message,
          life: 4000
        })
        setLoading(false);
        navigate('/');
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Password Reset failed',
          detail: response.message,
          life: 4000
        })
        setLoading(false);
        console.error('Password Reset failed:', response.message);
      }
    } catch (error) {
      setLoading(false);
      toast.current.show({
        severity: 'error',
        summary: 'Activation Failed',
        detail: "New account activation failed. Please try again in a moment.",
        life: 4000
      })
      console.error('Error:', error);
    }
  };

  const sendRegistrationLink = async (e) => {
    e.preventDefault();


    // Extract year, month, and day
    let year = dob.getFullYear();
    // Months are 0-indexed in JavaScript, so we add 1 to get the correct month
    let month = String(dob.getMonth() + 1).padStart(2, '0');
    let day = String(dob.getDate()).padStart(2, '0');

    // Format the date as YYYY-MM-DD
    let formattedDate = `${year}-${month}-${day}`;
    setLoading(true);
    try {
      const requestBody = {
        "username": emailAddress,
        "dob": formattedDate
      }

      const response = await AuthService.resetPassword(requestBody);

      if (response.status === "SUCCESS") {
        window.fbq('track', 'Forgot password/assistance', {
          status: "Success",
          detail: "OTP Sent"
        });

        setDisplayVerificationCodeField(true);
        setLoading(false);
      } else {
        setLoading(false)
        toast.current.show({
          severity: 'error',
          summary: 'Reset Failed',
          detail: response.message,
          life: 4000
        })
        window.fbq('track', 'Forgot password/assistance', {
          status: "Failed",
          detail: response.message
        });
        console.error('Activation failed:', response.message);
      }
    } catch (error) {
      setLoading(false);
      toast.current.show({
        severity: 'error',
        summary: 'Activation Failed',
        detail: "Account reset failed. Please try again in a moment.",
        life: 4000
      })
      window.fbq('track', 'Forgot password/assistance', {
        status: "Failed",
        detail: error
      });
      console.error('Error:', error);
    }
  };

  const handleChange = (e) => {

    const target = e.target;
    var { value } = target;

    const passwordValidation = {
      hasNumber: /\d/.test(value),
      hasUpperCase: /[A-Z]/.test(value),
      hasMinimumLength: value.length >= 8
    };

    setPasswordValidationRules(passwordValidation);

    setNewPassword(value);

  };




  return (


    <React.Fragment>
      <Navbar1 />
      <div className="pb-4 home-2 bg-home d-flex bg-light" id="home" style={{ width: 'auto' }}>
        <Toast ref={toast} />
        <Container>
          <Row className="align-items-center justify-content-between">
            <Col md={5}>
              <h3 className="home-title"><span style={{ color: "#2e3664", fontWeight: "400" }}>Wiremit Password Reset</span><br /><span style={{ color: "#268e9c", fontWeight: "60", fontSize: "x-large" }}>Account activation</span></h3>
            </Col>

            <Col md={7} >
              <div className="reg-move mt-5 contact glassmorphic-container-curve ">
                <Container className=''>

                  <Row className="align-items-center justify-content-center">

                    <div className="contact-box">
                      <div className="custom-form mt-4">

                        <Row className="align-items-center justify-content-center">
                          <div className="contact-box">
                            <div className="custom-form ">
                              <form method="post" name="myForm" >
                                <p id="error-msg" style={{ opacity: 1 }}> </p>

                                {!displayVerificationCodeField ?
                                  //Display when email is not sent

                                  !loading ?
                                    <>
                                      <Message className='mb-3' text={"Please provide your email address and date of birth (YYYY-MM-DD) to reset account password."} />
                                      <Row className='mt-3'>
                                        <Col lg={6}>
                                          <div className="form-group">
                                            <input
                                              name="emailAddress"
                                              id="emailAddress"
                                              type="text"
                                              className="form-control contact-form"
                                              placeholder='Enter email address'
                                              value={emailAddress}
                                              onChange={(e) => { setEmailAddress(e.target.value.trim()) }}

                                            />
                                          </div>
                                        </Col>
                                        <Col lg={6}>
                                          <div className="form-group">
                                            {/* <input
                                                name="dob"
                                                id="dob"
                                                type="text"
                                                className="form-control contact-form"
                                                placeholder='Enter DOB (YYYYMMDD)'
                                                value={dob}
                                                maxLength={10}
                                                onChange={(e) => { addDash(e.target.value)  }}
                                                required
                                                
                                       /> */}

                                            <DatePicker name="dob"
                                              id="dob"
                                              type="text"
                                              className="form-control contact-form"
                                              wrapperClassName="form-control"
                                              placeholderText='Enter DOB (YYYYMMDD)'
                                              title='Enter DOB (YYYYMMDD)'
                                              selected={dob} onChange={(date) => setDob(date)}
                                              dateFormat="yyyy-MM-dd"
                                              showYearDropdown
                                              scrollableYearDropdown
                                              maxDate={new Date()}
                                              yearDropdownItemNumber={100}
                                              required />
                                            {
                                              displayError ?
                                                <p
                                                  className="text-danger text-left"
                                                  style={{
                                                    fontSize: "0.9em",
                                                    textAlign: "left",
                                                  }}
                                                >
                                                  {displayErrorMessage}

                                                </p> : <></>
                                            }

                                          </div>
                                        </Col>
                                        <Row className="justify-content-center">
                                          <div className="col-lg-6 mb-4">
                                            <div className="text-center">
                                              <Button onClick={(e) => sendRegistrationLink(e)} className="btn btn-large bg-primary me-2 ">
                                                Reset Password
                                              </Button>
                                            </div>
                                          </div>
                                        </Row>
                                      </Row>
                                    </>

                                    :
                                    <LoadingSkeleton />
                                  :

                                  // Display when email is sent

                                  !loading ?

                                    <>
                                      <Message className='mb-3' text={"Please check your email and enter the code."} />
                                      <Row className='mt-3'>
                                        <Col lg={5}>
                                          <div className="form-group">
                                            <label className='text-muted'>Verification Code *</label>
                                            <input
                                              name="verificationCode"
                                              id="verificationCode"
                                              type="text"
                                              className="form-control contact-form"
                                              placeholder='Enter Verification code'
                                              value={emailVerificationCode}
                                              onChange={(e) => { setemailVerificationCode(e.target.value) }}
                                            />
                                          </div>
                                        </Col>
                                        {/* <Col lg={7}>
                                          <input
                                                name="newPassword"
                                                id="newPassword"
                                                type="password"
                                                className="form-control contact-form"
                                                placeholder='Enter new password'
                                                value={newPassword}
                                                onChange={(e) => { setNewPassword(e.target.value) }}
                                                
                                              /></Col> */}

                                        <Col lg={7}>
                                          <div className="form-group">
                                            <label className='text-muted'>Password*</label>
                                            <input
                                              name="password"
                                              id="password"
                                              type="password"
                                              className={`form-control contact-form ${passwordValidationRules && passwordValidationRules.hasMinimumLength && passwordValidationRules.hasNumber && passwordValidationRules.hasUpperCase ? 'is-valid' : 'is-invalid'}`}
                                              placeholder='password'
                                              value={newPassword}
                                              onChange={handleChange}
                                              required
                                            />
                                            {/* Display error message if the password is invalid */}
                                            {
                                              newPassword.length > 0 && <div className="invalid-feedback mb-4">
                                                Ensure your password
                                                {!passwordValidationRules?.hasNumber && ' includes at least one number.'}
                                                {!passwordValidationRules?.hasUpperCase && ' includes at least one uppercase letter.'}
                                                {!passwordValidationRules?.hasMinimumLength && ' is at least 8 characters long. '}
                                              </div>
                                            }

                                          </div>
                                        </Col>
                                        <Row className="justify-content-center">
                                          <div className="col-lg-6 mb-4">
                                            <div className="text-center">
                                              <Button onClick={(e) => sendNewPassword(e)} className="btn btn-large bg-primary me-2 ">
                                                Activate Account
                                              </Button>
                                            </div>
                                          </div>
                                        </Row>
                                      </Row>
                                    </>

                                    :
                                    <LoadingSkeleton />}


                              </form>
                            </div>
                          </div>
                        </Row>

                      </div>
                    </div>
                  </Row>


                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Section;
