import Box from "@mui/material/Box";
import Stack from '@mui/material/Stack';
import { useTheme } from "@mui/material/styles";
import { Toast } from "primereact/toast";
import React, { useRef, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import TagManager from "react-gtm-module";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import logoLight from "../../assets/images/logo-light.png";
import AuthService from "../../services/authServices";
import CurrencyConvertor from "./currencyConvertor";
import LoadingSkeleton from "./loadingSkeleton";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "wiremit-international",
    page: "signin",
  },
};

const DesktopTitle = () => <Box
  sx={{
    [useTheme().breakpoints.down('md')]: {
      display: "none"
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "1rem",
  }}
>
  <h3 style={{ color: "#2e3664", fontWeight: "400", fontSize: '40px' }} className="text-center home-title">
    Send Money Swiftly & Safely. Anywhere, Anytime!
    <br />
  </h3>
  <p
    className="text-center text-muted para-desc"
    style={{
      fontSize: "1em",
      fontWeight: "300",
      maxWidth: "600px"
    }}
  >
    Get unbeatable exchange rates and minimal fees, leaving more
    in your pocket. Experience the ultimate solution for your
    money transfer needs.
  </p>
</Box>

const Section = () => {
  TagManager.dataLayer(tagManagerArgs);
  const navigate = useNavigate();
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({
    username: "",
    password: "",
  });

  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);

  const validateForm = () => {
    let valid = true;
    const errors = {};

    // Validate First Name
    if (!formData.username.trim()) {
      errors.username = "Email is required";
      valid = false;
    }

    // Validate Last Name
    if (!formData.password.trim()) {
      errors.password = "Password is required";
      valid = false;
    }

    if (!formData.username.trim()) {
      errors.username = "Email is required";
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.username)) {
      errors.username = "Invalid email address";
      valid = false;
    }

    // Set form errors
    setFormErrors(errors);

    return valid;
  };

  const handleChange = (e) => {
    let { name, value } = e.target;

    value = value.trim()
    if (!value.trim()) {
      setFormErrors({
        ...formErrors,
        [name]: name + " is required",
      });
    } else {
      setFormErrors({
        ...formErrors,
        [name]: "",
      });
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValid = validateForm();

    if (!isValid) {
      toast.current.show({
        severity: "error",
        summary: "Login Failed",

        detail: (
          <div>
            {formErrors.username && <div>{formErrors.username}</div>}
            {formErrors.password && <div>{formErrors.password}</div>}
          </div>
        ),
        life: 4000,
      });
      return;
    }

    setLoading(true);
    try {
      const response = await AuthService.login(formData);
      setLoading(false);
      // 


      if (response.status === "SUCCESS") {
        window.fbq('track', 'Calculator/Pay/Sign in /Existing user', {
          status: "Success",
          detail: ""
        });
        navigate("/dashboard");
      } else {
        window.fbq('track', 'Calculator/Pay/Sign in /Existing user', {
          status: "Failed",
          detail: "invalid credentials"
        });
        toast.current.show({
          severity: "error",
          summary: "Login Failed",
          detail: "Invalid Credentials.",
          life: 4000,
        });
      }
    } catch (error) {
      window.fbq('track', 'Calculator/Pay/Sign in /Existing user', {
        status: "Failed",
        detail: error
      });
      toast.current.show({
        severity: "error",
        summary: "Login Failed",
        detail: error,
        life: 4000,
      });
    }
  };


  const theme = useTheme();

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      height="100%"
      sx={{
        marginY: "auto",
        marginX: "auto",
        [theme.breakpoints.down('md')]: {
          marginX: "1rem",
        }
      }}
      maxWidth={1140}>

      <DesktopTitle />

      {loading ? (
        <LoadingSkeleton />
      ) : (
        <Stack
          gap={5}
          justifyContent="center"
          flexDirection={"row"}
          sx={{
            [theme.breakpoints.down('md')]: {
              flexDirection: "column",
            }
          }}
        >
          <div className="col-md-5 glassmorphic-container-curve text-center">
            <div className="mt-5 mb-5 rounded-container">
              <Container>
                <Col lg={12}>
                  <img
                    src={logoLight}
                    className="logo-light"
                    alt=""
                    height="60"
                  />
                  <p
                    style={{
                      fontSize: "1.5em",
                      fontWeight: "500",
                    }}
                  >
                    Sign In
                  </p>
                </Col>

                <Row className="align-items-center justify-content-center">
                  <Col lg={12}>
                    <div className="contact-box">
                      <div className="custom-form ">
                        <form
                          method="post"
                          name="myForm"
                          onSubmit={handleSubmit}
                        >
                          <p
                            id="error-msg"
                            style={{ opacity: 10, color: "red" }}
                          >
                            {" "}
                          </p>
                          <Row className="">
                            <Col lg={12}>
                              <div className="mb-3 form-group">
                                <input
                                  name="username"
                                  id="username"
                                  type="text"
                                  value={formData.username}
                                  onChange={handleChange}
                                  className="form-control contact-form input-custom-style"
                                  placeholder="Email address"
                                />
                                {formErrors.username && (
                                  <p
                                    className="text-danger text-left"
                                    style={{
                                      fontSize: "0.9em",
                                      textAlign: "left",
                                    }}
                                  >
                                    *{formErrors.username}
                                  </p>
                                )}
                              </div>
                            </Col>

                            <Col lg={12}>
                              <div className="mb-4 form-group">
                                <input
                                  name="password"
                                  id="password"
                                  type={
                                    showPassword
                                      ? "text"
                                      : "password"
                                  }
                                  value={formData.password}
                                  onChange={handleChange}
                                  className="form-control contact-form mb-2"
                                  placeholder="Password"
                                />
                                <i
                                  id="icon"
                                  className="icon password-toggle"
                                  type="button"
                                  onClick={() =>
                                    setShowPassword(!showPassword)
                                  }
                                >
                                  {showPassword ? (
                                    <FaEyeSlash />
                                  ) : (
                                    <FaEye />
                                  )}
                                </i>
                                <Link
                                to="/forgot-password"
                                className="text-align-left text-primary mt-3"
                                onClick={window.fbq('track', 'Forgot password/assistance', {
                                  status: "Success request",
                                  detail: ""
                                })}
                              >
                                Forgot password?
                              </Link>
                                {formErrors.password && (
                                  <p
                                    className="text-danger text-left"
                                    style={{
                                      fontSize: "0.9em",
                                      textAlign: "left",
                                    }}
                                  >
                                    *{formErrors.password}
                                  </p>
                                )}
                              </div>
                            </Col>
                          </Row>

                          <Row className="justify-content-center">
                            <Stack className="text-center">
                              <Button
                                onClick={(e) => handleSubmit(e)}
                                className="btn btn-lg bg-primary me-2"
                                style={{
                                  fontSize: "1.1em",
                                  fontWeight: "500",
                                }}
                              >
                                Sign-in
                              </Button>
                              <Link
                                to="/quick-register"
                                className="justify-content-center text-primary fw-bold mt-4"
                                onClick={window.fbq('track', 'Calculator/Pay/create profile', {
                                  status: "Success",
                                  detail: ""
                                })}
                              >
                                Create New Profile{" "}
                                <i className="uil uil-angle-right-b align-middle"></i>
                              </Link>
                            </Stack>
                          </Row>
                        </form>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          <CurrencyConvertor />
        </Stack>
      )}
      <Toast ref={toast} />
    </Stack>
  );
};

export default Section;
