import React from "react";
import Layout from "../layout";
import Dashboard from "../pages/Dashboard/dashboard";
import Home from "../pages/Home/home";
import ResetPassword from "../pages/Home/resetPassword";
import SendMoney from "../pages/SendMoney/sendMoney";
import Signup from "../pages/Signup/signup";
import SignupCompleteProfile from "../pages/Signup/signupCompleteProfile";

import Verify from "../pages/Signup/verify";
import Success from "../pages/Success/success";
import QuickRegisterForm from "../pages/QuickRegister";
import VerifyV2 from "../pages/Verify"

const authProtectedRoutes = [
  { path: "/", component: <Home /> },

  // Dashboard
  {
    path: "/dashboard",
    component:
      <Layout>
        <Dashboard />
      </Layout>
  },

  // Signup
  {
    path: "/new-user", component:
        
      
       <QuickRegisterForm />
       
  },
  {
    path: "/quick-register", component:
      <QuickRegisterForm />
  },
  {
    path: "/complete-profile", component:
      <Layout>
        <SignupCompleteProfile />
      </Layout>
  },
  // Send money
  {
    path: "/send-money", component:
      <Layout>
        <SendMoney />
      </Layout>
  },

  {
    path: "/confirmation", component:
      <Layout>
        <Success />
      </Layout>
  },

  { path: "/verify/:activationCode", component: <Verify /> },

  {
    path: "/forgot-password", component:
      <Layout>
        <ResetPassword />
      </Layout>
  },

  {
    path: "/verify", component:
      <Layout>
        <VerifyV2 />
      </Layout>
  }
];

export default authProtectedRoutes;
